<template functional>
  <div :class="['thumbemote', 'thumbemote--' + props.face]" />
</template>

<script>
export default {
  props: {
    face: {
      type: String,
      default: '',
    },
  },
};
</script>
