<template>
  <div class="app__toolbar-wrapper" role="banner">
    <div class="app__toolbar above" :class="{ full_width: onHome && !modal }">
      <div class="app-toolbar-inner">
        <TheSoundButton v-if="!modal" />

        <BylineButton v-if="byline && !modal && !showUuid" :text="byline" />
      </div>
      <div v-if="onHome" class="app-toolbar-inner">
        <LanguagePickerButton v-if="!modal" />
      </div>
    </div>

    <div v-if="!inGame && !showUuid" class="app__toolbar below">
      <BaseButton
        v-if="privacyPolicyUrl"
        :href="privacyPolicyUrl"
        new-tab
        is-quaternary
        >{{ $l10n('privacy-policy') }}</BaseButton
      >

      <BaseButton
        v-if="termsOfUseUrl && !showUuid"
        :href="termsOfUseUrl"
        new-tab
        is-quaternary
        >{{ $l10n('terms-of-use') }}</BaseButton
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inGame: Boolean,
    onHome: Boolean,
    byline: String,
    privacyPolicyUrl: String,
    termsOfUseUrl: String,
    showUuid: Boolean,
  },
  computed: {
    modal() {
      const modals = this.$store.state.activeModals;
      if (modals.length > 0) return true;
      // "counting" the embeded RCT form as a modal, so that the sound & about
      // buttons are hidden.
      if (this.$store.state.currentScreen === 'embedForm') return true;
      return false;
    },
  },
};
</script>
