<template functional>
  <span :class="`icon icon--${props.type}`" />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
  },
};
</script>
