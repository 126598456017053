<template>
  <BaseScreen name="embedForm" class="embedForm">
    <div id="embedForm" class="embedForm__inner"></div>
  </BaseScreen>
</template>

<script>
import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';

export default {
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    session() {
      const session = this.$store.state.session;
      return session;
    },
    tgmSession() {
      return this.$store.state.tgmSession;
    },
  },
  mounted() {
    this.timeout();
  },
  methods: {
    timeout() {
      setTimeout(() => {
        if (this.session && !this.mounted) {
          this.createSurvey();
        } else if (!this.mounted) {
          this.timeout();
        }
      }, [100]);
    },
    createSurvey() {
      if (this.tgmSession) {
        this.$log.event({
          event_type: 'start_tgm_survey',
          target: this.tgmSession,
        });
      }
      createWidget(process.env.VUE_APP_RCT_FORM, {
        container: document.querySelector('#embedForm'),
        hidden: {
          session: this.session,
          tgmsession: this.tgmSession,
        },
        onSubmit: async () => {
          await this.$log.event({
            event_type: 'submitted_survey',
            target: 'TGM',
          });
          window.location.href = `https://tgm.mobi/status/fin?id=${this.tgmSession}`;
        },
      });
      this.mounted = true;
    },
  },
};
</script>

<style>
.embedForm {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.embedForm__inner {
  width: 80vw;
  height: 85vh;
}
</style>
