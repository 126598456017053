<template>
  <BaseScreen
    name="levelChange"
    :class="{
      levelscreen: true,
      'is-change-up': isLevelUp,
      'is-change-down': !isLevelUp,
      ['is-level-' + (level.index + 1)]: true,
      'show-recovery': showRecover,
    }"
    @toggle="toggleActive"
  >
    <transition mode="out-in">
      <div
        v-if="!showRecover"
        ref="levelupPane"
        key="change"
        tabindex="0"
        class="levelscreen__header"
      >
        <StarGraphic
          :number="level.index + 1"
          class="levelscreen__star levelscreen__star--change"
        />

        <h2>{{ title }}</h2>
        <p v-content="text"></p>
      </div>

      <div
        v-else-if="!isLevelUp && offerRecover && showRecover"
        ref="recoverPane"
        key="recover"
        tabindex="0"
        class="levelscreen__header levelscreen__header--recover"
      >
        <p>{{ $l10n('offer-recovery') }}</p>

        <StarGraphic class="levelscreen__star levelscreen__star--recover">
          <span class="star__label star__label--before">{{
            $l10n(level.index + 1)
          }}</span>
          <span class="star__label star__label--after">{{
            $l10n(level.index + 2)
          }}</span>
        </StarGraphic>

        <BaseButton
          name="recover"
          :location="level.name"
          is-primary
          is-rounded
          @click="$emit('recover')"
          >{{ $l10n('recover-level') }}</BaseButton
        >

        <BaseButton
          name="skip-recover"
          class="levelscreen__skip"
          :location="level.name"
          is-plain
          @click="$emit('done')"
          >{{ $l10n('skip') }}</BaseButton
        >
      </div>
    </transition>
    <div class="levelscreen__container">
      <div
        :class="`levelscreen__graphic ${overFive ? 'left' : ''} level-${
          level.index
        }`"
      />
      <div
        v-if="overFive"
        :class="'levelscreen__graphic right level-' + level.index"
      />
    </div>
    <div class="levelscreen__footer">
      <div class="levelscreen__buttons">
        <BaseButton
          v-if="!showUUID && (isLevelUp || didRecover)"
          name="share"
          :location="level.name"
          is-secondary
          @click="showModal('share')"
          >{{ $l10n('share-level') }}</BaseButton
        >
      </div>
      <BaseButton
        name="continue"
        :location="level.name"
        icon="next"
        is-primary
        is-circular
        class="levelscreen__continue"
        data-cy="continue"
        @click="maybeOfferRecovery"
        >{{ $l10n('continue') }}</BaseButton
      >
    </div>
  </BaseScreen>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    level: {
      type: Object,
      required: true,
    },
    offerRecover: {
      type: Boolean,
      required: true,
    },
    didRecover: Boolean,
    recoverNotice: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isActive: false,
      isLevelUp: false,
      showRecover: false,
    };
  },
  computed: {
    ...mapState({
      showUUID: state => state.survey.show_uuid,
    }),
    overFive() {
      return this.level.index > 4 ? true : false;
    },
    title() {
      if (this.didRecover) {
        return this.$l10n('level-recover');
      } else if (this.isLevelUp) {
        return this.$l10n('level-up');
      }

      return this.$l10n('level-down');
    },
    text() {
      let string = 'level-down-detail';
      if (this.didRecover) {
        string = 'level-recover-detail';
      } else if (this.isLevelUp) {
        string = 'level-up-detail';
      }

      return this.$l10n(string, `<strong>${this.level.title}</strong>`);
    },
  },
  watch: {
    level(newLevel, oldLevel) {
      this.isLevelUp = newLevel.index > oldLevel.index;
    },
  },
  updated() {
    // basically, read out the content that appears on all pages sans the first/home
    if (!this.showRecover) {
      setTimeout(() => {
        this.$refs.levelupPane.focus();
      }, 2000);
    } else if (!this.isLevelUp && this.offerRecover && this.showRecover) {
      setTimeout(() => {
        this.$refs.recoverPane.focus();
      }, 2000);
    }
  },
  methods: {
    toggleActive(isActive) {
      this.isActive = isActive;

      if (this.isActive) {
        setTimeout(
          () => this.$sounds.play(this.isLevelUp ? 'level-up' : 'level-down'),
          1000
        );
        this.showRecover = false;
      }
    },
    maybeOfferRecovery() {
      if (!this.isLevelUp && this.offerRecover && !this.showRecover) {
        this.showRecover = true;
      } else {
        this.$emit('done');
      }
    },
  },
};
</script>
